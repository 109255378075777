<template>
    <div>
        <div class="card-toolbar mb-5">
            <button @click="openAdvancedSearch" class="btn btn-outline-primary font-weight-bolder">
                <span><i class="la la-filter"></i> <span>{{$t('advanced_filter')}}</span></span>
            </button>
        </div>
        <!--begin::User-->
        <div class="card card-custom mb-5"  v-if="showAdvancedSearch">
            <div class="card-body">
                <div>
                    <div class="m-form m-form--fit m--margin-bottom-20" v-if="showAdvancedSearch">
                        <div class="row">

                            <div class="form-group col-md-6 mt-2 mb-2">
                                <label>{{$t('accounts_without_cost_center.account')}}</label>
                                <treeselect
                                        :options="accounts_list"
                                        :load-options="loadOptions"
                                        :multiple="false"
                                        :value="filters.id"
                                        @input="updateValue"
                                        :searchable="true"
                                        :show-count="true"
                                        :no-children-text="$t('No_sub_options')"
                                        :no-options-text="$t('No_options_available')"
                                        :no-results-text="$t('No_results_found')"
                                        :placeholder="$t('Select')">
                                </treeselect>
                            </div>
                            <div class="form-group d-inline-flex col-md-6 mt-6 mb-0">
                                <b-button class=" my-auto mb-0 mr-2" variant="primary" @click="doFilter"><i class="fas fa-search"></i> {{$t('search')}}</b-button>
                                <b-button class=" my-auto mb-0 mr-2" variant="danger" @click="resetFilter"><i class="fas fa-trash-restore"></i> {{$t('reset_search')}}</b-button>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card card-custom">
            <div class="card-body">
                <div class="row justify-content-end p-4">
                    <custom-export-data v-if="$can('accounts_without_cost_center.export_data')" :data-list="dataList" :fields="json_fields" :file-name="$t('MENU.accounts_without_cost_center')"></custom-export-data>
                </div>

                <v-server-table :class="'dataTable table-row-dashed'" :columns="columns" :options="options" ref="table">

                    <template slot="actions" slot-scope="props">
                        <v-icon small class="mr-2 text-info" v-b-tooltip.hover :title="$t('edit')" color="blue darken-2" v-if="$can('chart_accounts.update')" @click="editItem(props.row)">mdi-pencil</v-icon>
                        <v-icon small class="text-danger" v-b-tooltip.hover :title="$t('delete')" color="red darken-2" v-if="$can('chart_accounts.delete')" @click="deleteItem(props.row)">mdi-delete</v-icon>
                        <div class="w-100 m-3" v-if="$can('accounts_without_cost_center.assign_cost_center')">
                            <button class="btn btn-primary btn-sm" @click="assignCostCenter(props.row.id)">{{$t('accounts_without_cost_center.assign_cost_center')}}</button>
                        </div>
                    </template>
                </v-server-table>


                <!--end: Datatable-->
            </div>
        </div>
        <!--end::User-->

    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";

    export default {
        name: "index-accounts-assign-cost-center",
        components: {},
        data() {
            return {
                mainRoute: 'accounting/accounts-assign-cost-center',
                mainRouteChartAccounts: 'accounting/chart-accounts',
                subMainRoute: 'accounting/account-assign-cost-center',
                mainRouteDependency: 'base/dependency',
                showAdvancedSearch: false,
                dataList: [],
                filters: {
                    id: null,
                },
                accounts_list: [],
                columns: ['id', 'name', 'code', 'actions'],
            }
        },
        computed: {
            json_fields: function () {
                let fields = {};
                fields[this.$t('accounts_without_cost_center.id')] = 'id';
                fields[this.$t('accounts_without_cost_center.name')] = 'name';
                fields[this.$t('accounts_without_cost_center.code')] = 'code';

                return fields;
            },
            options: function () {
                let that = this;
                return {
                    texts: {
                        loadingError: that.$t('Something_went_wrong'),
                        filter: "",
                        limit: that.$t('records'),
                        filterBy: that.$t('Filter') + ' {column}',
                        count: ' ',
                        filterPlaceholder: that.$t('Search_query'),
                        loading: that.$t('Loading') + "...",
                    },
                    headings: {
                        id: that.$t('accounts_without_cost_center.id'),
                        name: that.$t('accounts_without_cost_center.name'),
                        code: that.$t('accounts_without_cost_center.code'),
                        actions: that.$t('actions'),

                    },
                    sortable: ['name'],
                    filterByColumn: false,
                    filterable: false,
                    customFilters: [{
                        name: 'alphabet',

                    }],
                    orderBy: {'column': 'id'},

                    alwaysShowPerPageSelect: true,
                    perPage: 10,
                    pagination: {chunk: 5, dropdown: false},
                    skin: 'table align-middle table-row-dashed fs-5 gy-5 text-center dataTable dtr-inline',
                    sortIcon: {base: 'fas ml-2', up: 'fa-sort-alpha-up', down: 'fa-sort-alpha-down', is: 'fa-sort'},
                    perPageValues: [5, 10, 15, 25],

                    requestFunction(data) {
                        let _params = {
                            ascending: 0,
                            byColumn: data.byColumn,
                            limit: data.limit,
                            orderBy: data.orderBy,
                            page: data.page,
                            filter: data.query,
                            ...that.filters,
                        }
                        return ApiService.query(that.mainRoute, {..._params});

                    },
                    responseAdapter(resp) {
                        that.dataList = resp.data.data.data;
                        return {
                            data: resp.data.data.data,
                            count: resp.data.data.total,
                        }
                    },

                }

            },
        },
        mounted() {
            // let that = this;
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.accounts_without_cost_center")}]);
            this.getAccount();
        },
        methods: {
            getFetch() {
                this.$refs.table.refresh();
            },
            openAdvancedSearch() {
                this.showAdvancedSearch = !this.showAdvancedSearch;
            },
            doFilter() {
                this.$refs.table.refresh();
            },
            resetFilter() {
                this.filters.id = null;
                this.$refs.table.refresh();
            },
            actionDelete(item) {
                ApiService.delete(this.mainRouteChartAccounts + "/" + item.id).then((response) => {
                    this.getFetch();
                    this.$successAlert(response.data.message)
                }).catch((error) => {
                    this.$errorAlert(error);
                })
            },
            editItem(item) {
                this.$router.push({name: 'chart-accounts.edit', params: {id: item.id}});
            },
            assignCostCenter(id) {
                this.$router.push({name: 'accounts-without-cost-center.create', params: {id: id}});
            },
            deleteItem(item) {
                this.$confirmAlert('', this.actionDelete, item);
            },

            updateValue(value) {
                this.filters.id = value
            },
            loadOptions() {
            },
            getAccount() {
                ApiService.get(`${this.mainRouteDependency}/${'account_parents'}`).then((response) => {
                    this.accounts_list = response.data.data;
                });
            },

        },
    };
</script>
